<template>
  <section class="section">
    <div class="wrapper">
      <div id="selected-items-container">
        <div
          v-for="(item, index) in selectedItems"
          :key="index"
          class="selected-item"
        >
          <div class="item-content">
            {{ item }}
          </div>
          <button
            type="button"
            class="item-btn close"
            @click="removeItem(item)"
          >
            &times;
          </button>
        </div>
      </div>
      <div class="actions">
        <ui5-button class="text-muted">
          {{ selectedItems.length }} selected.
        </ui5-button>
        <ui5-button class="show-options-btn" @click="showOptionsModal">
          &plus; Add new
        </ui5-button>
      </div>
    </div>

    <BaseModal
      v-model="modalShown"
      :header-text="`Add ${title.toLowerCase()} form`"
      no-close
    >
      <section id="options-container">
        <div class="form-group">
          <ui5-label
            class="option-input label"
            wrapping-type="Normal"
            :for="id + '-input'"
          >
            Enter {{ title.toLowerCase() }}
          </ui5-label>
          <ui5-input
            :id="`${id}-input`"
            :ref="`${id}-input`"
            class="samples-responsive-padding-bottom option-input"
            placeholder=""
          />
          <ui5-button
            design="Emphasized"
            class="add-options-btn"
            @click="addItem"
          >
            Add {{ title.toLowerCase() }}
          </ui5-button>
          <ui5-button class="add-options-btn" @click="closeOptionsModal">
            Cancel
          </ui5-button>
        </div>
      </section>
    </BaseModal>
  </section>
</template>
<script>
import BaseModal from '../components/common/BaseModal.vue';

export default {
  name: 'SelectMultiple',
  components: { BaseModal },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },

  data: function () {
    return {
      selected: [],
      modalShown: false,
    };
  },

  watch: {
    selectedItems(newValues, _) {
      this.selected = newValues;
    },
  },

  created() {
    this.selected = this.selectedItems;
  },

  methods: {
    showOptionsModal() {
      this.modalShown = true;
    },
    closeOptionsModal() {
      this.modalShown = false;
    },

    addItem() {
      const inputField = this.$refs[`${this.id}-input`];
      if (this.selected.indexOf(inputField.value) != -1) {
        inputField.value = '';
        this.closeOptionsModal();
        return;
      }
      this.selected.push(inputField.value);
      inputField.value = '';
      this.$emit(this.id, this.selected);
      this.closeOptionsModal();
    },

    removeItem(key) {
      this.selected = this.selected.filter((item) => item != key);
      this.$emit(this.id, this.selected);
    },
  },
};
</script>

<style scoped>
.section {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 0px;
}
.wrapper {
  padding: 0.1rem 0;
}
#selected-items-container {
  border: 1px solid #89919a;
  border-radius: 2px;
  padding: 8px;
  min-height: 34px;
}
.selected-item {
  border: 1px solid #89919a;
  background: #dee6ed;
  color: #354a5f;
  border-radius: 40px;
  padding: 4px 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-height: 20px;
  margin: 2px;
  overflow: hidden;
}
.item-content {
  margin-left: 8px;
}
.item-btn {
  color: #354a5f;
  margin-left: 8px;
  font-size: 16px;
  width: 18px;
  height: 18px;
  border: none !important;
  outline: none !important;
  transition: 0.1s ease;
}
.item-btn:hover {
  transform: scale(1.09);
}
.actions {
  line-height: 1.5;
  font-size: 14px;
  padding: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.actions ui5-button {
  padding: 4px 0px 0px 0px;
  height: 18px;
  border: none;
}
.option-input {
  display: block;
  width: 100%;
}

.label {
  text-align: left;
  padding-bottom: 0.5rem;
}
.add-options-btn {
  margin: 15px 0px 15px 15px;
  float: right;
}
</style>
