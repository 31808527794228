<template>
  <section>
    <b-row>
      <b-col cols="3">
        <b-row>
          <b-col>
            <FilterCard
              :from-date="searchFromDate"
              :to-date="searchToDate"
              @searchFilter="searchFilter"
              @clearFilter="clearFilter"
              @set-from-date="setFromDate"
              @set-to-date="setToDate"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <ui5-busy-indicator
              id="busy-indicator"
              size="Medium"
              :active="busyLoading"
              style="width: 100%"
            >
              <EmployeeList
                :employees="employees"
                style="width: 100%"
                @clicked="showEmployee"
              />
            </ui5-busy-indicator>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <Notification
              v-if="!employeeSelected"
              @select-notification="selectNotification"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <BasicEmployee
              v-if="employeeSelected"
              :given-employee="employeeSelected"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ReimbursementCaseList
              v-if="employeeSelected"
              :employee="employeeSelected"
              :from-date="searchFromDate"
              :to-date="searchToDate"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import api from '../api/index';
import FilterCard from '../components/FilterCard.vue';
import EmployeeList from '../components/EmployeeList.vue';
import BasicEmployee from '../components/BasicEmployee.vue';
import ReimbursementCaseList from '../components/ReimbursementCaseList.vue';
import Notification from '../components/NotificationList.vue';
import { dkConstants } from '../dk-constants';

export default {
  name: 'HomePage',
  components: {
    FilterCard,
    EmployeeList,
    BasicEmployee,
    ReimbursementCaseList,
    Notification,
  },

  data: function () {
    return {
      employees: [],
      employeeSelected: null,
      searchFromDate: '',
      searchToDate: '',
      busyLoading: false,
    };
  },
  mounted() {
    this.initDates();
  },
  methods: {
    searchFilter(filter) {
      this.busyLoading = true;
      this.employees = [];
      api
        .getEmployees(filter)
        .then((response) => {
          this.employees = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.busyLoading = false;
        });
    },
    clearFilter() {
      this.employees = [];
      this.employeeSelected = null;
      this.initDates();
    },
    showEmployee(employee) {
      this.employeeSelected = employee;
    },
    setFromDate(newDate) {
      this.searchFromDate = newDate;
    },
    setToDate(newDate) {
      this.searchToDate = newDate;
    },
    initDates() {
      const today = new Date();
      const tempToDate = new Date(today);
      const tempFromDate = new Date(
        today.setDate(today.getDate() - dkConstants.FROM_DATE_DAYS_AGO)
      );

      this.searchFromDate = this.dateToString(tempFromDate);
      this.searchToDate = this.dateToString(tempToDate);
    },
    dateToString(tempDate) {
      const dateYear = tempDate.getFullYear();
      const dateMonth = dkConstants.MONTH_NUMBERS[tempDate.getMonth()];
      let dateDay = tempDate.getDate();
      if (dateDay < 10) {
        dateDay = '0' + dateDay;
      }
      return `${dateYear}-${dateMonth}-${dateDay}`;
    },
    async selectNotification(caseId) {
      try {
        const response = await api.getReimbursementCaseStatesWithEmployee(
          caseId
        );
        const { employee, startDate, endDate } = response.data;

        this.$store.commit('home/RESET_SELECTED_FILTER');
        this.employeeSelected = employee;
        this.searchFromDate = startDate;
        this.searchToDate = endDate;
      } catch (err) {
        return this.$store.dispatch(
          'showToastMessage',
          'Cannot navigate to case'
        );
      }
    },
  },
};
</script>

<style scoped></style>
