<template>
  <b-container v-if="shouldDisplay" class="input-align" fluid>
    <b-row v-if="showDateRange" class="py-2 px-0">
      <b-col cols="12" class="px-2">
        <ui5-title level="H5">Select rotation weeks for the employee</ui5-title>
      </b-col>
      <b-col cols="4" class="px-0">
        <DateInput
          v-model="startDate"
          label="From Date (shift start)"
          required
          :readonly="readonly"
          placeholder="From Date"
          @input="validateDateRange"
        />
      </b-col>
      <b-col cols="4">
        <DateInput
          v-model="endDate"
          label="To Date (shift end)"
          required
          :readonly="readonly"
          placeholder="To Date"
          :min="startDate"
          @input="validateDateRange"
        />
      </b-col>
      <b-col cols="12" class="px-0">
        <ui5-title v-if="dateError" level="H6" style="color: red">
          Error: {{ dateError }}
        </ui5-title>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="py-2 px-0">
        <ui5-table>
          <ui5-table-column
            v-if="showNumWeeks"
            slot="columns"
            class="week-column"
            >Week
          </ui5-table-column>
          <ui5-table-column
            v-for="(day, index) in displayedDaysOfWeek"
            slot="columns"
            :key="index"
          >
            {{ day }}
          </ui5-table-column>

          <ui5-table-row v-for="(week, weekIdx) in allWeekNumbers" :key="`week-${week}`">
            <ui5-table-cell
              v-if="showNumWeeks"
              class="week-column"
              wrapping-type="Normal"
              style="horiz-align: center"
            >
              <span>{{ week }}</span>
            </ui5-table-cell>
            <ui5-table-cell
              v-for="(day, dayIdx) in displayedDaysOfWeek"
              :key="`${week}-${dayIdx}`"
            >
              <b-container fluid>
                <b-row no-gutters class="justify-content-start mt-1">
                  <b-col :cols="displayedDaysOfWeek.length === 7 ? 6 : 3">
                    <ui5-input
                      :id="`hours-${week}-${dayIdx}`"
                      placeholder="HH"
                      type="Number"
                      max="24"
                      min="0"
                      class="time-input"
                      :readonly="readonly"
                      :value="getHourValue(week, dayIdx)"
                      :value-state="getTimeValueState(week, dayIdx)"
                      @input="
                        updateHourValue(week, weekIdx, dayIdx, $event.target.value)
                      "
                    />
                  </b-col>
                  <b-col :cols="displayedDaysOfWeek.length === 7 ? 6 : 3">
                    <ui5-input
                      :id="`minutes-${week}-${dayIdx}`"
                      placeholder="MM"
                      type="Number"
                      max="59"
                      min="0"
                      class="time-input"
                      :readonly="readonly"
                      :value="getMinuteValue(week, dayIdx)"
                      :value-state="getTimeValueState(week, dayIdx)"
                      @input="
                        updateMinuteValue(week, weekIdx, dayIdx, $event.target.value)
                      "
                    />
                  </b-col>
                </b-row>
              </b-container>
            </ui5-table-cell>
          </ui5-table-row>

          <ui5-table-group-row v-if="showNumWeeks"
            >Weeks in shift: {{ weekNumbers.length }}
          </ui5-table-group-row>

          <ui5-table-group-row>
            <ui5-button
              design="Transparent"
              icon="reset"
              class="mt-1"
              :disabled="readonly"
              @click="resetTimeFields"
              >Reset fields</ui5-button
            >
          </ui5-table-group-row>
        </ui5-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import inputMixin from '../mixins/input-mixin';
import Vue from 'vue';
import { isNumeric } from '../utils';

const WorkingHourTypes = {
  VARYING: 'Varying working hours',
  FIXED: 'Fixed working hours',
  WORK_SCHEDULE: 'Work schedule',
  WEEKEND_WORK: 'Weekend work',
};

const get7DaysFromNow = () => {
  return new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
};

const WorkingHoursSelector = Vue.component('WorkingHoursSelector', {
  mixins: [inputMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    linkedValues: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    startDate: new Date().toISOString().substring(0, 10),
    endDate: get7DaysFromNow().toISOString().substring(0, 10),
    dateError: null,
    timeData: {},
    weekNumbers: [],
  }),
  computed: {
    workingHoursType() {
      return this.linkedValues.workingHoursType;
    },
    shouldDisplay() {
      return (
        this.linkedValues?.workingHoursType &&
        this.linkedValues.workingHoursType !== WorkingHourTypes.VARYING
      );
    },
    showDateRange() {
      return this.workingHoursType === WorkingHourTypes.WORK_SCHEDULE;
    },
    showNumWeeks() {
      return this.workingHoursType === WorkingHourTypes.WORK_SCHEDULE;
    },
    allWeekNumbers() {
      return this.workingHoursType === WorkingHourTypes.WORK_SCHEDULE
        ? this.weekNumbers
        : [1];
    },
    daysOfWeek() {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1);
      const numDaysInWeek = 7;
      const week = [];
      for (let i = 0; i < numDaysInWeek; i++) {
        week.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return week.map((date) =>
        date.toLocaleString(window.navigator.language, {
          weekday: 'long',
        })
      );
    },
    displayedDaysOfWeek() {
      return this.workingHoursType === WorkingHourTypes.WEEKEND_WORK
        ? this.daysOfWeek.slice(5)
        : this.daysOfWeek;
    },
    timeErrors() {
      const errors = [];
      Object.entries(this.timeData).forEach(([week, days]) => {
        Object.entries(days).forEach(([dayNum, day], dayIdx) => {
          if (isNumeric(day.hours)) {
            const hours = Number(day.hours);
            if (!Number.isInteger(hours)) {
              errors.push({
                week,
                dayIdx,
                msg: 'Hour value is not an integer',
              });
            }
            if (hours < 0 || hours > 24) {
              errors.push({ week, dayIdx, msg: 'Hour value is not valid' });
            }
          }
          if (isNumeric(day.minutes)) {
            const minutes = Number(day.minutes);
            if (!Number.isInteger(minutes)) {
              errors.push({
                week,
                dayIdx,
                msg: `Minute value ${minutes} is not an integer`,
              });
            }
            if (minutes < 0 || minutes > 59) {
              errors.push({ week, dayIdx, msg: 'Minute value is not valid' });
            }
          }
          if (isNumeric(day.hours) && isNumeric(day.minutes)) {
            if (Number(day.hours) === 24 && Number(day.minutes) > 0) {
              errors.push({
                week,
                dayIdx,
                msg: 'Hour value is 24, minute value should be 0',
              });
            }
          }
        });
      });
      return errors;
    },
    hasTimeErrors() {
      return this.timeErrors.length > 0;
    },
    isValid() {
      return this.dateError === null && this.timeErrors.length === 0;
    },
  },
  watch: {
    workingHoursType(newVal) {
      this.weekNumbers = [];
      this.resetTimeFields();
      this.startDate = new Date().toISOString().substring(0, 10);
      this.endDate = get7DaysFromNow().toISOString().substring(0, 10);
    },
  },
  mounted() {
    if (
      this.linkedValues.workShiftFirstDay &&
      this.linkedValues.workShiftLastDay
    ) {
      this.startDate = this.linkedValues.workShiftFirstDay;
      this.endDate = this.linkedValues.workShiftLastDay;
      this.calculateWeeks();
    }
    this.initTimeDataFromLinkedValues();
  },
  methods: {
    initTimeDataFromLinkedValues() {
      const workingTime = this.linkedValues.workingTime;
      if (!workingTime) {
        this.initTimeData();
        return;
      }

      const startingWeek = this.allWeekNumbers[0];
      const result = {};
      for (let weekIdx = 0; weekIdx < this.allWeekNumbers.length; weekIdx++) {
        const week = startingWeek + weekIdx;
        result[week] = {};
        this.displayedDaysOfWeek.forEach((day, dayIdx) => {
          result[week][dayIdx] = this.initTimelessDay();
          const hours = workingTime[`workingTime-week${weekIdx}-day${dayIdx}-hours`];
          if (hours) {
            result[week][dayIdx].hours = hours;
          }
          const minutes = workingTime[`workingTime-week${weekIdx}-day${dayIdx}-minutes`];
          if (minutes) {
            result[week][dayIdx].minutes = minutes;
          }
        });
      }
      this.timeData = { ...result };
    },
    initTimeData(originalWeekNumbers) {
      this.allWeekNumbers.forEach((week) => {
        if (originalWeekNumbers && originalWeekNumbers.includes(week)) {
          return;
        }
        this.timeData[week] = {};
        this.displayedDaysOfWeek.forEach((day, dayIdx) => {
          this.timeData[week][dayIdx] = this.initTimelessDay();
        });
      });
    },
    initTimelessDay() {
      return {
        hours: null,
        minutes: null,
      };
    },
    calculateWeeks() {
      const startWeek = this.getWeekOfYear(new Date(this.startDate));
      const endWeek = this.getWeekOfYear(new Date(this.endDate));
      if (startWeek > endWeek) {
        this.weekNumbers = Array.from(
          { length: 52 - startWeek + 1 },
          (_, i) => startWeek + i
        ).concat(Array.from({ length: endWeek }, (_, i) => i + 1));
      } else {
        this.weekNumbers = Array.from(
          { length: endWeek - startWeek + 1 },
          (_, i) => startWeek + i
        );
      }
    },
    get7DaysFromNow() {
      return new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    },
    validateDateRange() {
      const valid = new Date(this.startDate) <= new Date(this.endDate);
      this.dateError = null;
      if (!valid) {
        this.dateError = 'Invalid date range';
        return;
      }
      const originalWeekNumbers = [...this.weekNumbers];
      this.calculateWeeks();
      this.validateWeeks();
      let emitTimeChanges = false;
      if (
        this.isValid &&
        this.workingHoursType === WorkingHourTypes.WORK_SCHEDULE
      ) {
        this.emitWorkScheduleValues();
        emitTimeChanges = true;
      } else {
        this.emitNullWorkScheduleValues();
      }
      this.initTimeData(originalWeekNumbers);
      if (emitTimeChanges) {
        this.emitTimeChanges();
      }
    },
    validateWeeks() {
      const minWeeksAllowed = 2;
      const maxWeeksAllowed = 6;
      if (this.weekNumbers.length > maxWeeksAllowed) {
        this.dateError = `Max ${maxWeeksAllowed} weeks allowed`;
        return;
      }
      if (this.weekNumbers.length < minWeeksAllowed) {
        this.dateError = `Min ${minWeeksAllowed} weeks required`;
        return;
      }
      this.dateError = null;
    },
    emitWorkScheduleValues() {
      this.$emit('change', 'workShiftFirstDay', this.startDate);
      this.$emit('change', 'workShiftLastDay', this.endDate);
      this.$emit('change', 'workShiftWeekQuantity', this.weekNumbers.length);
    },
    emitNullWorkScheduleValues() {
      this.$emit('change', 'workShiftFirstDay', null);
      this.$emit('change', 'workShiftLastDay', null);
      this.$emit('change', 'workShiftWeekQuantity', null);
    },
    emitTimeChanges() {
      this.allWeekNumbers.forEach((week, weekIdx) => {
        this.displayedDaysOfWeek.forEach((day, dayIdx) => {
          this.$emit('change', `workingTime-week${weekIdx}-day${dayIdx}-hours`, this.timeData[week][dayIdx].hours || 0);
          this.$emit('change', `workingTime-week${weekIdx}-day${dayIdx}-minutes`, this.timeData[week][dayIdx].minutes || 0);
        });
      });
    },
    getHourValue(week, day) {
      return this.timeData[week]?.[day]?.hours;
    },
    updateHourValue(week, weekIdx, dayIdx, value) {
      if (!this.timeData[week][dayIdx]) {
        this.timeData[week][dayIdx] = this.initTimelessDay();
      }
      this.timeData[week][dayIdx].hours = value;
      this.timeData = { ...this.timeData };
      if (this.isValid) {
        this.$emit('change', `workingTime-week${weekIdx}-day${dayIdx}-hours`, value);
      }
    },
    getMinuteValue(week, day) {
      return this.timeData[week]?.[day]?.minutes;
    },
    updateMinuteValue(week, weekIdx, dayIdx, value) {
      if (!this.timeData[week][dayIdx]) {
        this.timeData[week][dayIdx] = this.initTimelessDay();
      }
      this.timeData[week][dayIdx].minutes = value;
      this.timeData = { ...this.timeData };
      if (this.isValid) {
        this.$emit('change', `workingTime-week${weekIdx}-day${dayIdx}-minutes`, value);
      }
    },
    resetTimeFields() {
      this.allWeekNumbers.forEach((week, weekIdx) => {
        this.displayedDaysOfWeek.forEach((day, dayIdx) => {
          this.$emit('change', `workingTime-week${weekIdx}-day${dayIdx}-hours`, 0);
          this.$emit('change', `workingTime-week${weekIdx}-day${dayIdx}-minutes`, 0);
        });
      });
      this.timeData = {};
      this.initTimeData();
    },
    getWeekOfYear(date) {
      // This is all a bit too gigabrain for me so see: https://stackoverflow.com/a/6117889/5281821
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      const numMsInDay = 86400000;
      return Math.ceil(((d - yearStart) / numMsInDay + 1) / 7);
    },
    getTimeValueState(week, dayIdx) {
      return this.timeErrors.some(
        (error) =>
          Number(error.week) === Number(week) &&
          Number(error.dayIdx) === Number(dayIdx)
      )
        ? 'Error'
        : 'None';
    },
  },
});

export default WorkingHoursSelector;
</script>

<style scoped>
ui5-input.time-input {
  width: 50px;
  min-width: 50px;
}

.week-column {
  width: 32px;
  min-width: 32px;
}
</style>
