<template>
    <section class="section">
      <ui5-card class="medium">
        <ui5-card-header
          slot="header"
          title-text="Filter options"
          subtitle-text="Search Time Data"
        >
          <ui5-icon slot="avatar" name="filter" />
        </ui5-card-header>
            <div class="wrapper">
            <form id="time-data-filter-form">
                <div class="form-group">
                    <ui5-label
                        for="fromDate"
                        class="filter-input label"
                        wrapping-type="Normal"
                        >From Date
                    </ui5-label>
                    <input
                        id="fromDate"
                        type="date"
                        class="filter-input datepicker"
                        format-pattern="yyyy-MM-dd"
                        style="width: 210px"
                        :value="fromDate"
                        @change="setFromDate"
                    />
                </div>

                <div class="form-group">
                    <ui5-label
                        for="toDate"
                        class="filter-input label"
                        wrapping-type="Normal"
                        >To Date
                    </ui5-label>
                    <input
                        id="toDate"
                        type="date"
                        style="width: 210px"
                        format-pattern="yyyy-MM-dd"
                        class="filter-input datepicker"
                        :value="toDate"
                        @change="setToDate"
                    />
                </div>
                <div class="form-group">
                <ui5-label
                    class="filter-input label"
                    wrapping-type="Normal"
                    for="employeeIdInput"
                >
                    Employee Id
                </ui5-label>
                <ui5-input
                    :value="employeeId"
                    @input="event => employeeId = event.target.value"
                    id="employeeIdInput"
                    class="filter-input"
                    placeholder="Employee Id"
                />
                </div>
            </form>  
            <div class="filter-buttons">
                <ui5-button class="filter-button" icon="search" @click="buildFilter">
                    Search
                </ui5-button>
                <ui5-button class="filter-button" icon="clear-filter" @click="clearFilter">
                    Clear Filter
                </ui5-button>
            </div>
            </div>  
        </ui5-card>
    </section>
    
</template>

<script>
import api from '../api/index';

export default {
  name: 'TimeReportFilterCard',
  props: {
    fromDate: {
      type: String,
      required: true,
    },
    toDate: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      employeeId: '',
    };
  },
  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {

    /**
     * Clear the current values for the filter
     */
    clearFilter() {
      [
        ...document.getElementsByClassName('filter-input'),
      ].forEach((input) => {
        input.value = '';
      });
      this.employeeId = '';
      this.$emit('clearFilter', {});
    },

    buildFilter() {
      const filter = {
        fromDate: this.fromDate,
        toDate: (() => {
          const tempDate = new Date(this.toDate);
          tempDate.setDate(tempDate.getDate() + 1);
          return tempDate.toISOString().split('T')[0];
        })(),
        employeeId: this.employeeId
      }
      this.$emit('searchFilter', filter);
    },

    setFromDate(e) {
      this.$emit('set-from-date', e.target.value);
    },
    setToDate(e) {
      this.$emit('set-to-date', e.target.value);
    },
  },
};
</script>


<style scoped>
.wrapper {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.form-group {
  float: left;
  display: block;
}

.filter-input {
  display: table-cell;
  width: 6rem;
  vertical-align: middle;
}

.label {
  text-align: left;
  padding-right: 0.5rem;
}

.filter-buttons {
  width: 100%;
  text-align: center;
  padding: 0rem 0.25rem 0.5rem 0.25rem;
}

.filter-button {
  width: 45%;
}
</style>