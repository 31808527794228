<template>
  <div class="text-center">
    <div id="progress-indicator" class="pb-1">
      <span
        v-for="stepNum in numSteps"
        :key="stepNum"
        class="step"
        :class="getStepClass(stepNum - 1)"
      />
    </div>

    <ui5-button
      id="prevBtn"
      design="Emphasized"
      class="nav-button"
      :disabled="currentStep === 0"
      @click="$emit('prev')"
    >
      Previous
    </ui5-button>
    <ui5-button
      id="nextBtn"
      design="Emphasized"
      class="footer-button"
      :disabled="currentStep === numSteps - 1"
      @click="$emit('next')"
    >
      Next
    </ui5-button>
  </div>
</template>

<script>
export default {
  name: 'ProgressIndicator',
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    numSteps: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    getStepClass(stepNum) {
      const isActive = stepNum === this.currentStep;
      const isFinished = stepNum < this.currentStep;
      return `${isActive ? 'active' : ''} ${isFinished ? 'finish' : ''}`;
    },
  },
};
</script>

<style scoped>
.nav-button {
  width: 6rem;
}

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #4caf50;
}
</style>
