<template>
  <b-card class="mt-2">
    <b-card-title> 404 - Page not Found </b-card-title>
  </b-card>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped></style>
