<template>
  <section class="section">
    <div v-if="list" style="width: 100%">
      <ui5-busy-indicator
        :active="caseListBusy"
        size="Medium"
        style="width: 100%"
      >
        <ui5-card class="medium">
          <ui5-card-header
            slot="header"
            title-text="Reimbursement Cases"
            :subtitle-text="reimbursementCaseSubheading"
          />
          <ui5-list
            id="reimbursementList"
            style="height: 480px"
            separators="None"
          >
            <ui5-li
              v-for="(reimbursement, i) in reimbursementCases"
              :key="i"
              icon="add"
              :additional-text="reimbursement.currentState"
              :additional-text-state="reimbursement.infoState"
              @click="showDetail(i)"
            >
              <div>
                {{ reimbursement.reimbursementType }}
                <span v-if="reimbursement.startDate != null">
                  from {{ reimbursement.startDate }}
                </span>
                <span v-if="reimbursement.endDate != null">
                  to {{ reimbursement.endDate }}
                </span>
                <span v-if="reimbursement.endDate == null"> (Ongoing) </span>
              </div>
            </ui5-li>
          </ui5-list>
        </ui5-card>
      </ui5-busy-indicator>
    </div>

    <div v-else style="width: 100%">
      <ui5-card class="medium">
        <ui5-card-header
          slot="header"
          title-text="Reimbursement Case States"
          :subtitle-text="subheading"
          interactive
          @click="showList"
        >
          <ui5-icon slot="avatar" name="less" />
        </ui5-card-header>

        <ReimbursementCaseStateList
          :reimbursement="selectedReimbursement"
          style="height: 435px; overflow-y: auto"
        />

        <div id="footer-container" style="float: right" class="my-2">
          <ui5-button class="footer-button" @click="resetCase">
            Reset Case
          </ui5-button>
          <ui5-button class="footer-button" @click="openModal()">
            Close Case
          </ui5-button>
        </div>
      </ui5-card>
    </div>
    <BaseModal
    value="true"
    v-model="closeCaseClicked"
    scrollable
    no-close
    :header-text="closeCaseWarningModalTitle"
    footer-align="none"
    >
      <template>
        <ui5-label
          class="modal-input pb-0"
          wrapping-type="Normal"
        >
        {{ closeCaseWarningModalMessage }}
        </ui5-label>
      </template>
      <template #footer-buttons>
        <b-row class="py-2" align-v="center">
          <b-col></b-col>
          <b-col>
            <div class="text-right">
              <ui5-button
                ref="myButtonRef"
                id="confirmCloseCaseBtn"
                design="Emphasized"
                @click="closeCase()"
              >
                Confirm
              </ui5-button>
            </div>
          </b-col>
          <b-col>
            <div class="text-right">
              <ui5-button
                id="cancelCloseCaseBtn"
                design="Negative"
                @click="closeModal()"
              >
                Cancel
              </ui5-button>
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>
      </template>
    </BaseModal>
  </section>
</template>

<script>
import api from '../api';
import ReimbursementCaseStateList from './ReimbursementCaseStateList.vue';
import BaseModal from './common/BaseModal.vue'
import { dkConstants } from '../dk-constants';
import { mapState } from 'vuex';

export default {
  name: 'ReimbursementCaseList',
  components: {
    ReimbursementCaseStateList,
    BaseModal,
  },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
    toDate: {
      type: [String, Date],
      default: '',
    },
    fromDate: {
      type: [String, Date],
      default: '',
    },
  },

  data: function () {
    return {
      reimbursementCases: [],
      list: true,
      selectedReimbursement: {},
      subheading: '',
      lastFetchedEmployeeId: null,
      caseListBusy: false,
      closeCaseClicked: false,
      closeCaseWarningModalTitle: "Warning: Closing Case",
      closeCaseWarningModalMessage: "Are you sure you want to close this case? Closing a case will finalize all associated actions and cannot be undone.",
    };
  },

  computed: {
    reimbursementCaseSubheading() {
      const employeeName = this.employee.name || 'Employee';
      const fromDate = new Date(this.fromDate).toLocaleDateString(
        'en-US',
        dkConstants.DATE_FORMAT_OPTIONS
      );
      const toDate = new Date(this.toDate).toLocaleDateString(
        'en-US',
        dkConstants.DATE_FORMAT_OPTIONS
      );

      return `Reimbursement Cases for ${employeeName} from ${fromDate} to ${toDate}`;
    },
    ...mapState({
      filterSelected: (state) => state.home.selectedFilter,
      filterSelectedValue: (state) => state.home.selectedFilterValue,
    }),
  },

  watch: {
    employee(newVal) {
      if (
        !this.lastFetchedEmployeeId ||
        newVal.id !== this.lastFetchedEmployeeId
      ) {
        this.getReimbursementCases();
      }
    },
  },

  /**
   * Get the list of reimbursement cases for the employee
   */
  mounted() {
    this.getReimbursementCases();
  },

  methods: {
    openModal(){
      this.closeCaseClicked = true;
    },

    closeModal(){
      this.closeCaseClicked = false;
    },
    
    getReimbursementCases() {
      this.caseListBusy = true;

      let type = null;
      if (this.filterSelected === 'Type') {
        type = this.filterSelectedValue;
      }

      const params = {
        employeeId: this.employee.id,
        startDate: this.fromDate,
        endDate: this.toDate,
        type,
      };
      api
        .getReimbursementCases(params)
        .then((response) => {
          this.reimbursementCases = response.data;
          this.reimbursementCases.forEach((element) => {
            element.caseDetails = JSON.parse(element.caseDetails);
            let startDate = element.caseDetails.startDate;
            let endDate = element.caseDetails.endDate;

            if (startDate != null) {
              startDate = new Date(startDate);
              element.startDate = startDate.toLocaleDateString(
                'en-US',
                dkConstants.DATE_FORMAT_OPTIONS
              );
            }
            if (endDate != null) {
              endDate = new Date(endDate);
              element.endDate = endDate.toLocaleDateString(
                'en-US',
                dkConstants.DATE_FORMAT_OPTIONS
              );
            }

            if (
              element.currentStatus === 'Notification waiting for signoff' &&
              element.currentState === 'Pending'
            ) {
              element.infoState = 'Warning';
            } else if (
              element.currentStatus === 'Ready for notification' &&
              element.currentState === 'Signed off'
            ) {
              element.infoState = 'Success';
            } else if (
              element.currentStatus === 'Ready for notification' &&
              element.currentState === 'Unsigned'
            ) {
              element.infoState = 'Information';
            } else if (
              element.currentStatus === 'Ready for notification' &&
              element.currentState === 'Sent'
            ) {
              element.infoState = 'Information';
            } else if (
              element.currentStatus === 'Request waiting for signoff' &&
              element.currentState === 'Pending'
            ) {
              element.infoState = 'Warning';
            } else if (
              element.currentStatus === 'Ready for request' &&
              element.currentState === 'Signed off'
            ) {
              element.infoState = 'Information';
            } else if (
              element.currentStatus === 'Ready for request' &&
              element.currentState === 'Unsigned'
            ) {
              element.infoState = 'Information';
            } else if (
              element.currentStatus === 'Ready for request' &&
              element.currentState === 'Sent'
            ) {
              element.infoState = 'Information';
            } else if (
              element.currentStatus === 'Submission to Nemrefusion failed' &&
              element.currentState === 'Response received'
            ) {
              element.infoState = 'Error';
            } else if (
              element.currentStatus ===
                'Submission to Nemrefusion successful' &&
              element.currentState === 'Response received'
            ) {
              element.infoState = 'Success';
            } else if (
              element.currentStatus === 'Reimbursement received' &&
              element.currentState === 'Reimbursement received'
            ) {
              element.infoState = 'Success';
            } else if (
              element.currentStatus === 'Case closed' &&
              element.currentState === 'Done'
            ) {
              element.infoState = 'Success';
            }

            this.lastFetchedEmployeeId = this.employee.id;
            this.list = true;
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.caseListBusy = false;
        });
    },

    showDetail(index) {
      const reimbursement = this.reimbursementCases[index];
      this.list = false;
      this.selectedReimbursement = reimbursement;
      this.subheading =
        reimbursement.reimbursementType + ' for ' + this.employee.name;
      if (reimbursement.startDate != null) {
        this.subheading += ' from ' + reimbursement.startDate;
      }
      if (reimbursement.endDate != null) {
        this.subheading += ' to ' + reimbursement.endDate;
      }
    },

    showList() {
      this.list = true;
      this.selectedReimbursement = {};
      this.getReimbursementCases();
    },

    closeCase() {
      api
        .closeCase(this.selectedReimbursement)
        .then((response) => {
          // handle response
        })
        .catch((error) => {
          console.error(error);
        });
        this.closeModal();
    },

    resetCase() {
      api
        .resetCase(this.selectedReimbursement.reimbursementCaseID)
        .then((response) => {
          this.list = false;
          this.selectedReimbursement = {};
          this.getReimbursementCases();
          alert(response.data.message);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.footer-button {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}
</style>
