<template>
  <section class="section">
    <ui5-card class="medium">
      <ui5-card-header
        slot="header"
        title-text="Notifications"
        subtitle-text="Select a notification message to navigate to the affected reimbursement case"
      >
        <ui5-icon slot="avatar" name="notification-2" />
      </ui5-card-header>
      <ui5-list
        id="notification-list"
        style="height: 490px"
        separators="Inner"
        class="pb-2"
      >
        <ui5-li-notification
          v-for="(item, index) in viewNotifications"
          :key="index"
          :ref="`notification-${index}`"
          :title-text="item.heading"
          :priority="item.priority"
          :style="getCursorStyle(item)"
          @click="navigateToCase(item, index)"
          @mouseover="updateCursorStyles(item, index)"
        >
          Date: {{ item.date }} <br />

          Description: {{ item.description }}

          <ui5-notification-action
            id="acceptBtnInPopover"
            slot="actions"
            text="Accept"
            @click="deleteNotification(item, index)"
          ></ui5-notification-action>
        </ui5-li-notification>
      </ui5-list>
      <ui5-button
        v-if="getNumPages > 1"
        class="pagination-button"
        :disabled="page === 0"
        @click="prevPage"
        >Prev</ui5-button
      >
      <ui5-button
        v-for="i in getNumPages"
        :key="i"
        :class="i === page + 1 ? '' : 'pagination-button'"
        @click="goToPage(i - 1)"
        >{{ i }}</ui5-button
      >
      <ui5-button
        v-if="getNumPages > 1"
        class="pagination-button"
        :disabled="page === getNumPages - 1"
        @click="nextPage"
        >Next</ui5-button
      >
    </ui5-card>
  </section>
</template>

<script>
import api from '../api/index';
import { dkConstants } from '../dk-constants';
import { debounce } from '../utils';

export default {
  name: 'NotificationList',
  components: {},

  data: function () {
    return {
      notifications: [],
      viewNotifications: [],
      page: 0,
      numPages: 0,
    };
  },

  computed: {
    getNumPages: function () {
      if (this.numPages > 5) return 5;
      return this.numPages;
    },
  },

  created() {
    api
      .getNotifications()
      .then((response) => {
        this.notifications = response.data;
        this.viewNotifications = this.notifications.slice(
          0,
          dkConstants.NOTIFICATION_PAGE_SIZE
        );
        this.numPages = Math.ceil(
          this.notifications.length / dkConstants.NOTIFICATION_PAGE_SIZE
        );
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    deleteNotification(notification, index) {
      const deleteIndex =
        this.page * dkConstants.NOTIFICATION_PAGE_SIZE + index;
      this.notifications.splice(deleteIndex, 1);
      this.viewNotifications = this.notifications.slice(
        this.page * dkConstants.NOTIFICATION_PAGE_SIZE,
        this.page * dkConstants.NOTIFICATION_PAGE_SIZE +
          dkConstants.NOTIFICATION_PAGE_SIZE
      );
      this.numPages = Math.ceil(
        this.notifications.length / dkConstants.NOTIFICATION_PAGE_SIZE
      );
      if (this.page >= this.numPages) this.prevPage();
      notification.read = true;

      api.updateNotification(notification);
    },
    getCursorStyle(item) {
      return item.caseId ? 'cursor: pointer' : 'cursor: default';
    },
    navigateToCase(item) {
      if (item.caseId) {
        this.$emit('select-notification', item.caseId);
      }
    },
    nextPage() {
      this.page++;
      this.goToPage(this.page);
    },
    goToPage(page) {
      this.page = page;
      if (
        this.page * dkConstants.NOTIFICATION_PAGE_SIZE >=
        this.notifications.length
      ) {
        this.page--;
      }
      this.viewNotifications = this.notifications.slice(
        this.page * dkConstants.NOTIFICATION_PAGE_SIZE,
        this.page * dkConstants.NOTIFICATION_PAGE_SIZE +
          dkConstants.NOTIFICATION_PAGE_SIZE
      );
    },
    prevPage() {
      this.page--;
      if (this.page > 0) {
        this.goToPage(this.page);
      } else {
        this.goToPage(0);
      }
    },
    updateCursorStyles: debounce(function (item, index) {
      // eslint-disable-next-line no-invalid-this
      const notificationLi = this.$refs[`notification-${index}`];
      if (notificationLi && notificationLi[0]) {
        notificationLi[0].shadowRoot.childNodes[1].style.cursor = item.caseId
          ? 'pointer'
          : 'default';
      }
    }),
  },
};
</script>

<style scoped>
.pagination-button {
  border: none;
  background-color: white;
  color: #0a6ed1;
}
</style>
