<template>
  <ui5-card class="medium pb-2">
    <ui5-panel>
      <div slot="header" class="py-2">
        <ui5-title level="H3" class="pl-2">
          {{ title }}
        </ui5-title>
        <div v-if="subtitle" class="pl-2">
          {{ subtitle }}
        </div>
      </div>

      <div class="jumbotron py-5">
        <slot />
      </div>
    </ui5-panel>
  </ui5-card>
</template>

<script>
export default {
  name: 'BaseConfigCard',
  props: {
    title: {
      type: String,
      default: 'Config',
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
