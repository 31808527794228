<template>
  <div class="login-page">
    <div class="login-form">
      <form class="p-5 m-5">
        <b-container fluid>
          <b-row class="justify-content-center">
            <b-col cols="7">
              <label for="username">Username</label>
              <input
                id="username"
                v-model="username"
                type="text"
                class="form-control"
                placeholder="Enter username"
              />
            </b-col>
            <b-col cols="7">
              <label for="password">Password</label>
              <input
                id="password"
                v-model="password"
                type="password"
                class="form-control"
                placeholder="Password"
              />
            </b-col>
            <b-col cols="7" class="text-center py-2">
              <ui5-button
                type="submit"
                class="btn btn-primary"
                :disabled="isInvalid"
                @click="login"
              >
                Login
              </ui5-button>
            </b-col>
          </b-row>
        </b-container>
      </form>
    </div>
  </div>
</template>

<script>
import api from '../api/index';

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  computed: {
    isInvalid() {
      return (
        !this.username ||
        this.username.length === 0 ||
        !this.password ||
        this.password.length === 0
      );
    },
  },
  methods: {
    login() {
      api
        .login(this.username, this.password)
        .then((response) => {
          if (response.status === 200) {
            const path = '/home';
            if (this.$route.path !== path) {
              this.$router.push(path);
            }
          }
        })
        .catch(async (error) => {
          await this.$store.dispatch('displayErrorDialog', {
            component: 'MessageStrip',
            props: {
              text: error.response.data.message,
              type: 'Negative',
              id: 'login-error',
            },
          });
        });
    },
  },
};
</script>
