<template>
  <section>
    <b-row>
      <b-col cols="3">
        <b-row>
          <b-col>
            <TimeReportFilterCard
              :from-date="searchFromDate"
              :to-date="searchToDate"
              @searchFilter="searchFilter"
              @clearFilter="clearFilter"
              @set-from-date="setFromDate"
              @set-to-date="setToDate"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="9">
        <b-row>
          <b-col>
            <ui5-busy-indicator
              id="busy-indicator"
              size="Medium"
              :active="busyLoading"
              style="width: 100%; height: 100%;"
            >
              <TimeReportList style="width: 100%; height: 100%"
                v-if="timeReports"
                :time-reports="timeReports"
                :from-date="searchFromDate"
                :to-date="searchToDate"
              />
            </ui5-busy-indicator>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import api from '../api/index';
import TimeReportFilterCard from './TimeReportFilterCard.vue';
import TimeReportList from './TimeReportList.vue';
import { dkConstants } from '../dk-constants';
export default {
  name: 'TimeReport',
  components: {
    TimeReportFilterCard,
    TimeReportList,
  },

  data: function () {
    return {
        timeReports: {},
        searchFromDate: '',
        searchToDate: '',
        busyLoading: false,
    };
  },
  mounted() {
    this.initDates();
  },
  methods: {
    searchFilter(filter) {
      this.busyLoading = true;
      this.timeReports = {};
      api
        .getEmployeeTimeDataReport(filter)
        .then((response) => {
          this.timeReports = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.busyLoading = false;
        });
    },
    clearFilter() {
      this.timeReports = {};
      this.initDates();
    },
    setFromDate(newDate) {
      this.searchFromDate = newDate;
    },
    setToDate(newDate) {
      this.searchToDate = newDate;
    },
    initDates() {
      const today = new Date();
      const tempToDate = new Date(today);
      const tempFromDate = new Date(
        today.setDate(today.getDate() - dkConstants.FROM_DATE_DAYS_AGO)
      );

      this.searchFromDate = this.dateToString(tempFromDate);
      this.searchToDate = this.dateToString(tempToDate);
    },

    dateToString(tempDate) {
      const dateYear = tempDate.getFullYear();
      const dateMonth = dkConstants.MONTH_NUMBERS[tempDate.getMonth()];
      let dateDay = tempDate.getDate();
      if (dateDay < 10) {
        dateDay = '0' + dateDay;
      }
      return `${dateYear}-${dateMonth}-${dateDay}`;
    },
  },
};
</script>

<style scoped>

</style>