<template>
  <base-modal :value="value" header-text="Edit Rate" @input="handleDialogClose">
    <b-container v-if="rateToEdit" fluid>
      <b-row no-gutters class="align-items-center">
        <b-col cols="6">
          <DateInput
            v-model="rateToEdit.startDate"
            label="Start Date"
            required
            placeholder="Start Date"
          />
        </b-col>
        <b-col cols="6">
          <DateInput
            v-if="rateToEdit.endDate"
            v-model="rateToEdit.endDate"
            label="End Date"
            required
            :placeholder="'End Date'"
          />
          <ui5-title v-else level="H6" class="text-black-100">
            End Date:<br />
            Present
          </ui5-title>
        </b-col>
        <b-col cols="6">
          <NumberInput
            v-model="rateToEdit.value"
            label="Hourly Rate"
            required
            name="rate"
            @input="(val) => (rateToEdit.value = Number(val))"
          />
        </b-col>
      </b-row>
    </b-container>
    <template #footer-buttons>
      <ui5-button
        design="Emphasized"
        :disabled="!isRateValid"
        @click="saveEditedRate"
      >
        Save
      </ui5-button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '../common/BaseModal.vue';

export default {
  name: 'RateEditModal',
  components: { BaseModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    originalRate: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    rateToEdit: null,
  }),
  computed: {
    isRateValid() {
      const startDateValid = this.rateToEdit?.startDate?.length > 0;
      const endDateValid =
        this.rateToEdit?.endDate === null ||
        this.rateToEdit?.endDate?.length > 0;
      const rateValueValid = this.rateToEdit?.value >= 0;
      return startDateValid && endDateValid && rateValueValid;
    },
  },
  watch: {
    originalRate(newVal) {
      this.rateToEdit = { ...newVal };
    },
    'rateToEdit.endDate'(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.rateToEdit.endDate = oldVal;
      }
    },
  },
  methods: {
    saveEditedRate() {
      this.$emit('save-rate', this.rateToEdit);
    },
    handleDialogClose() {
      this.$emit('input', false);
      this.rateToEdit = null;
    },
  },
};
</script>

<style scoped></style>
