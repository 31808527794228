<template>
  <div class="input-align">
    <ui5-label
      v-if="!hideLabel"
      class="modal-input"
      :value-state="isValid ? '' : 'Error'"
      v-bind="labelRules"
      wrapping-type="Normal"
    >
      {{ label }}
    </ui5-label>
    <ui5-input
      class="modal-input"
      type="Number"
      :value-state="isValid ? '' : 'Error'"
      v-bind="inputRules"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import inputMixin from '../mixins/input-mixin';

const NumberInput = Vue.component('NumberInput', {
  mixins: [inputMixin],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputRules() {
      return {
        type: 'number',
        id: this.name,
        name: this.name,
        value: this.value,
        placeholder: this.placeholder,
        required: this.required,
        readonly: this.readonly,
      };
    },
    labelRules() {
      return {
        for: this.name,
        required: this.required,
      };
    },
  },
});

export default NumberInput;
</script>

<style scoped>
.modal-input {
  display: block;
}
</style>
