<template>
  <div>
    <ui5-message-strip
      :id="id"
      class="messagestrip"
      :design="type"
      @close="$emit('close')"
    >
      {{ text }}
    </ui5-message-strip>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      value: 'Information' | 'Positive' | 'Negative' | 'Warning',
      default: '',
    },
    text: {
      type: String,
      value: '',
      default: '',
    },
    id: {
      type: String,
      value: '',
      default: '',
    },
  },
};
</script>
