<template>
  <BaseModal
    :value="value"
    scrollable
    :header-text="nemRefusionStatus"
    @input="$emit('input', false)"
  >
    <div>
      <ui5-list style="height: 300px" separators="Inner">
        <ui5-li
          v-for="(item, index) in nemRefusionTextList"
          :key="index"
          :additional-text="item.messageCategory"
          :additional-text-state="item.messageCategory"
          :description="item.messageCode"
          class="list-item"
        >
          {{ item.messageText }}
        </ui5-li>
      </ui5-list>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from './common/BaseModal.vue';

export default {
  name: 'NemStatusModal',
  components: { BaseModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    nemRefusionStatus: {
      type: String,
      default: '',
    },
    nemRefusionTextList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.jumbotron {
  margin: auto;
  margin-right: 0;
  width: 50rem;
}
</style>
