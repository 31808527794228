<template>
  <section class="section">
    <ui5-card class="medium">
      <ui5-panel>
        <ui5-title slot="header" level="H3" class="pl-2">
          Finance Files
        </ui5-title>
        <div class="jumbotron py-5">
          <ui5-table class="content-padding">
            <ui5-table-column slot="columns">
              <ui5-label>ID</ui5-label>
            </ui5-table-column>
            <ui5-table-column slot="columns">
              <ui5-label>File Name</ui5-label>
            </ui5-table-column>
            <ui5-table-column slot="columns">
              <ui5-label>Created</ui5-label>
            </ui5-table-column>
            <ui5-table-column slot="columns"> </ui5-table-column>
            <ui5-table-row
              v-for="(item, index) in viewFinanceFiles"
              :key="index"
            >
              <ui5-table-cell>{{ item.id }}</ui5-table-cell>
              <ui5-table-cell>{{ item.fileName }}</ui5-table-cell>
              <ui5-table-cell>{{
                convertToDate(item.timestamp)
              }}</ui5-table-cell>
              <ui5-table-cell>
                <ui5-button class="mt-1" @click="showFinanceFile(item)">
                  Open
                </ui5-button>
              </ui5-table-cell>
            </ui5-table-row>
          </ui5-table>
          <ui5-button
            v-if="getNumPages > 1"
            class="pagination-button"
            @click="prevPage"
            >Prev</ui5-button
          >
          <ui5-button
            v-for="i in getNumPages"
            :key="i"
            class="pagination-button"
            @click="goToPage(i - 1)"
            >{{ i }}</ui5-button
          >
          <ui5-button
            v-if="getNumPages > 1"
            class="pagination-button"
            @click="nextPage"
            >Next</ui5-button
          >
        </div>
      </ui5-panel>
    </ui5-card>
    <FinanceAuditFileModal v-model="modalShown" :file="file" />
  </section>
</template>

<script>
import Vue from 'vue';
import api from '../api/index';
import FinanceAuditFileModal from '../components/FinanceAuditFileModal.vue';

const FinanceAuditFilesList = Vue.component('FinanceAuditFilesList', {
  components: {
    FinanceAuditFileModal,
  },

  data: function () {
    return {
      financefiles: [],
      viewFinanceFiles: [],
      modalShown: false,
      page: 0,
      pageSize: 50,
      numPages: 0,
      file: {
        fileName: '',
        fileData: '',
      },
      fileData: null,
      cardHeader: '',
    };
  },

  computed: {
    getNumPages: function () {
      if (this.numPages > 5) return 5;
      return this.numPages;
    },
  },

  created() {
    api
      .financeFiles()
      .then((response) => {
        this.financefiles = response.data;
        this.viewFinanceFiles = this.financefiles.slice(0, this.pageSize);
        this.numPages = Math.ceil(this.financefiles.length / this.pageSize);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    nextPage() {
      this.page++;
      this.goToPage(this.page);
    },
    goToPage(page) {
      this.page = page;
      if (this.page * this.pageSize >= this.financefiles.length) {
        this.page--;
      }
      this.viewFinanceFiles = this.financefiles.slice(
        this.page * this.pageSize,
        this.page * this.pageSize + this.pageSize
      );
    },
    prevPage() {
      this.page--;
      if (this.page > 0) {
        this.goToPage(this.page);
      } else {
        this.goToPage(0);
      }
    },
    convertToDate(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
    showFinanceFile(file) {
      this.file = file;

      this.modalShown = true;
    },
  },
});

export default FinanceAuditFilesList;
</script>
<style scoped>
.pagination-button {
  border: none;
  background-color: white;
  color: #0a6ed1;
}
</style>
