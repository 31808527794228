<template>
  <ui5-dialog ref="modal" :header-text="headerText" @keydown="handleEscape">
    <slot />
    <div
      v-if="!noClose || !!$slots['footer-buttons']"
      slot="footer"
      class="dialog-footer py-1"
      :class="footerAlignClass"
    >
      <slot name="footer-buttons" />
      <ui5-button v-if="!noClose" @click="close">
        {{ closeText }}
      </ui5-button>
    </div>
  </ui5-dialog>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    headerText: {
      type: String,
      default: 'Modal',
    },
    value: {
      type: Boolean,
      default: false,
    },
    noClose: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: 'Close',
    },
    footerAlign: {
      type: String,
      default: 'center',
      validator: (val) => ['center', 'left', 'right', 'none'].includes(val),
    },
    centerText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    footerAlignClass() {
      if (this.footerAlign === 'none') {
        return '';
      }
      return `text-${this.footerAlign}`;
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.$refs.modal.show();
      } else {
        this.$refs.modal.close();
      }
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    handleEscape(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  height: auto;
  width: 66%;
}
</style>
