<template>
  <section class="section">
    <ui5-card class="medium">
      <ui5-card-header
        slot="header"
        title-text="Employees"
        :subtitle-text="employeesFoundSubtitle"
        interactive
        @click="clearSelect"
      >
        <ui5-icon v-if="!selectedEmployee" slot="avatar" name="group" />
        <ui5-icon v-else slot="avatar" name="clear-filter" />
      </ui5-card-header>

      <ui5-list v-if="employees.length === 0" no-data-text="No Employees" />

      <!-- TODO fix search icon -->
      <div style="text-align: center">
        <ui5-input
          v-if="employees.length"
          id="searchInput"
          placeholder="Search Employee"
          style="width: 95%; margin: 0.25rem"
          :value="searchCriteria"
          @input="searchEmployee"
        >
          <ui5-icon id="plus" slot="icon" name="search" />
        </ui5-input>
      </div>

      <ui5-list
        v-if="employees.length"
        id="employeeList"
        style="height: 300px"
        separators="Inner"
      >
        <ui5-li
          v-for="(employee, i) in filteredItems"
          :key="i"
          :image="employee.base64Image"
          :additional-text="employee.id"
          :description="'Number of cases (' + employee.numberOfCases + ')'"
          @click="showEmployee(i)"
        >
          {{ employee.name }}
        </ui5-li>
      </ui5-list>
    </ui5-card>
  </section>
</template>

<script>
export default {
  name: 'EmployeeList',
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
  },

  data: function () {
    return {
      selectedEmployee: false,
      searchCriteria: '',
    };
  },

  computed: {
    filteredItems() {
      if (this.searchCriteria === '') {
        return this.employees;
      } else {
        return this.employees.filter((employee) => {
          if (employee.name != null) {
            return employee.name
              .toLowerCase()
              .includes(this.searchCriteria.toLowerCase());
          }
        });
      }
    },
    employeesFoundSubtitle() {
      return this.employees?.length > 0
        ? `Employees found (${this.employees.length})`
        : 'Employees found (0)';
    },
  },

  methods: {
    showEmployee(index) {
      const employee = this.filteredItems[index];
      if (employee == null) {
        return;
      }
      this.$emit('clicked', employee);
      this.selectedEmployee = true;
    },

    clearSelect() {
      this.$emit('clicked', null);
      this.selectedEmployee = false;
    },
    searchEmployee(e) {
      this.searchCriteria = e.target.value;
    },
  },
};
</script>
