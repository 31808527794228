<template>
  <section class="section" style="padding-top: 0px">
    <ui5-busy-indicator
      id="case-detail-busy"
      :active="busyLoading"
      size="Medium"
      style="width: 100%"
    >
      <ui5-table id="case-states-table" sticky-column-header>
        <ui5-table-column slot="columns" class="date">
          <ui5-label wrapping-type="Normal"> Date </ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="status">
          <ui5-label wrapping-type="Normal"> Status </ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="state">
          <ui5-label wrapping-type="Normal"> State </ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="form">
          <ui5-label wrapping-type="Normal"> Form </ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="nemrefusion">
          <ui5-label wrapping-type="Normal"> NemRefusion Status </ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="received-amount">
          <ui5-label wrapping-type="Normal"> Received Amount </ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="own-comment">
          <ui5-label wrapping-type="Normal"> Own Comment </ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="action" />

        <ui5-table-row
          v-for="reimbursementCase in reimbursementCaseHistory"
          :key="reimbursementCase.id"
        >
          <ui5-table-cell>
            {{ reimbursementCase.date1 }} <br />
            {{ reimbursementCase.date2 }}
          </ui5-table-cell>
          <ui5-table-cell>{{ reimbursementCase.status }}</ui5-table-cell>
          <ui5-table-cell>{{ reimbursementCase.state }}</ui5-table-cell>
          <ui5-table-cell>{{ reimbursementCase.formCode }}</ui5-table-cell>
          <ui5-table-cell>
            <ui5-button
              v-if="reimbursementCase.nemRefusionStatus != null"
              :design="reimbursementCase.design"
              @click="showStatus(reimbursementCase)"
            >
              {{ reimbursementCase.nemRefusionStatus }}
            </ui5-button>
          </ui5-table-cell>
          <ui5-table-cell>{{
            reimbursementCase.receivedAmount
          }}</ui5-table-cell>
          <ui5-table-cell>{{
            reimbursementCase.ownComment || reimbursementCase.comment
          }}</ui5-table-cell>
          <ui5-table-cell>
            <ui5-button
              v-if="showOpenButton(reimbursementCase)"
              @click="showModal(reimbursementCase)"
            >
              Open
            </ui5-button>
          </ui5-table-cell>
        </ui5-table-row>
      </ui5-table>
    </ui5-busy-indicator>

    <NemStatusModal
      v-model="nemStatusModalShown"
      :nem-refusion-status="nemRefusionStatus"
      :nem-refusion-text-list="nemRefusionTextList"
    />

    <CaseModal
      v-model="caseModalShown"
      :schema="schema"
      :form-data="formData"
      :reimbursement-case="selectedReimbursementCase"
      @set-form-data="setFormData"
      @set-reimbursement-case="setReimbursementCase"
      @signAndSubmit="signAndSubmit"
      @submit="submit"
      @save="save"
      @reset-data="resetDataChanges"
      @update-form="updateForm"
    />
  </section>
</template>

<script>
import api from '../api';

import CaseModal from './CaseModal.vue';
import NemStatusModal from '../components/NemStatusModal.vue';
import { dkConstants } from '../dk-constants';

export default {
  name: 'ReimbursementCaseStateList',
  components: {
    CaseModal,
    NemStatusModal,
  },

  props: {
    reimbursement: {
      type: Object,
      default: () => {},
    },
  },

  data: function () {
    return {
      reimbursementCaseHistory: [],
      schema: [],
      formData: {},
      formType: '',
      nemRefusionStatus: '',
      nemRefusionTextList: [],
      caseModalShown: false,
      nemStatusModalShown: false,
      selectedReimbursementCase: null,
      busyLoading: false,
    };
  },

  mounted() {
    this.getReimbursementCaseStates();
  },

  methods: {
    showOpenButton(reimbursementCase) {
      return (
        reimbursementCase.formDTO != null &&
        (reimbursementCase.dataMigration == null ||
          !reimbursementCase.dataMigration)
      );
    },

    /**
     * Get reimbursement case states for the current reimbursement
     */
    getReimbursementCaseStates() {
      this.busyLoading = true;

      const params = {
        reimbursementId: this.reimbursement.reimbursementCaseID,
      };

      const options = dkConstants.SHORT_MONTH_DATE_FORMAT_OPTIONS;

      api
        .getReimbursementCaseStates(params)
        .then((response) => {
          this.reimbursementCaseHistory = response.data;
          this.reimbursementCaseHistory.forEach((element) => {
            element.caseDetails = this.reimbursement.caseDetails;
            if (element.formDTO !== null) {
              element.formCode = element.formDTO.form;
              element.formEnum = element.formDTO.formEnum;
            } else {
              element.formCode = '';
            }

            const date = new Date(element.date);
            element.date1 = `${date.toLocaleDateString('en-US', options)}`;
            element.date2 = `${date.toLocaleTimeString()}`;
            element.readonly = true;

            if (element.caseStateData !== null) {
              const responseJSON = JSON.parse(element.caseStateData);
              element.nemRefusionStatus = responseJSON.overallWorstStatus;
              element.nemRefusionTextList = responseJSON.responseMessages;

              if (element.nemRefusionStatus === 'Success') {
                element.design = 'Positive';
              } else if (element.nemRefusionStatus === 'Information') {
                element.design = 'Default';
              } else if (element.nemRefusionStatus === 'Warning') {
                element.design = 'Attention';
              } else {
                element.design = 'Negative';
              }
            }
          });
          this.reimbursementCaseHistory[0].readonly = false;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.busyLoading = false;
        });
    },
    updateForm(newFormData) {
      console.log(newFormData);
      this.formData = { ...newFormData };
    },
    setFormData(formData) {
      this.formData = formData;
    },
    setReimbursementCase(caseData) {
      this.selectedReimbursementCase = caseData;
    },

    showModal(reimbursementCase) {
      this.schema = [];
      this.selectedReimbursementCase = reimbursementCase;
      api
        .getSchema(reimbursementCase.formDTO)
        .then((response) => {
          this.formType = response.data.formType;
          this.schema = response.data.schema;

          if (reimbursementCase.readonly) {
            this.schema.forEach((tab) => {
              tab.tabContents[0].collapsed = false;
              tab.tabContents[0].sectionContents.forEach((element) => {
                element.readonly = true;
                this.formData[element.name] = element.value;
              });

              for (let index = 1; index < tab.tabContents.length; index++) {
                tab.tabContents[index].collapsed = true;
                tab.tabContents[index].sectionContents.forEach((element) => {
                  element.readonly = true;
                  this.formData[element.name] = element.value;
                });
              }
            });
          } else {
            this.schema.forEach((tab) => {
              tab.tabContents[0].collapsed = false;
              tab.tabContents[0].sectionContents.forEach((element) => {
                this.formData[element.name] = element.value;
              });

              for (let index = 1; index < tab.tabContents.length; index++) {
                tab.tabContents[index].collapsed = true;
                tab.tabContents[index].sectionContents.forEach((element) => {
                  this.formData[element.name] = element.value;
                });
              }
            });
          }

          this.caseModalShown = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    showStatus(reimbursement) {
      this.nemRefusionStatus = reimbursement.nemRefusionStatus;
      this.nemRefusionTextList = reimbursement.nemRefusionTextList;
      this.nemStatusModalShown = true;
    },

    save(formData, reimbursementCase) {
      api
        .save(formData, reimbursementCase, this.reimbursement.caseDetails)
        .then((response) => {
          this.getReimbursementCaseStates();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    signAndSubmit(formData, reimbursementCase) {
      api
        .signAndSubmit(formData, reimbursementCase)
        .then((response) => {
          this.getReimbursementCaseStates();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    submit(formData, reimbursementCase) {
      api
        .submit(formData, reimbursementCase)
        .then((response) => {
          this.getReimbursementCaseStates();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    resetDataChanges(oldFormData) {
      this.formData = oldFormData;
    },
  },
};
</script>

<style scoped>
#case-states-table ui5-table-cell > * {
  vertical-align: middle;
}

ui5-table-column {
  width: 11%;
}
</style>
