<template>
    <section class="section">
      <div v-if="list" style="width: 100%">
        <ui5-busy-indicator
          :active="timeReportListBusy"
          size="Medium"
          style="width: 100%"
        >
          <ui5-card class="medium">
            <ui5-card-header
              slot="header"
              title-text="Time Data Reports"
            />
            <ui5-list
              id="timeReportList"
              style="height: 480px"
              separators="None"
            >
              <ui5-li
                v-for="(timeReport, i) in timeReports.monthlyReports"
                :key="i"
                icon="add"
                @click="showDetail(i)"
              >
                <div>
                  Time Data Report for 
                  <span v-if="timeReports.employeeName">
                    {{ timeReports.employeeName }}
                  </span>
                  <span v-if="timeReport.startDate != null">
                    from {{ timeReport.startDate }}
                  </span>
                  <span v-if="timeReport.endDate != null">
                    to {{ timeReport.endDate }}
                  </span>
                </div>
              </ui5-li>
            </ui5-list>
          </ui5-card>
        </ui5-busy-indicator>
      </div>

      <div v-else style="width: 100%">
      <ui5-card class="medium">
        <ui5-card-header
          slot="header"
          :title-text="heading"
          interactive
          @click="showList"
        >
          <ui5-icon slot="avatar" name="less" />
        </ui5-card-header>

        <TimeReportDetailed
          :time-report="selectedTimeReport"
          style="height: 650px; overflow-y: auto"
        />
      </ui5-card>
      </div>
    </section>
  </template>

<script>
import TimeReportDetailed from './TimeReportDetailed.vue';

export default {
    name: "TimeReportList",
    components: {
      TimeReportDetailed
    },

    props: {
        timeReports: {
          type: Object,
          default: () => {},
        },
        fromDate: {
            type: [String, Date],
            default: '',
        },
        toDate:{
            type: [String, Date],
            default: '',
        }
    },
    data: function(){
        return{
            selectedTimeReport: {},
            timeReportListBusy: false,
            list: true,
            heading: '',

        }
    },

    watch: {
      timeReports(){
        this.list = true;
      }
    },
    
    methods: {
        showDetail(index) {
            const timeData = this.timeReports.monthlyReports[index];
            this.list = false;
            this.selectedTimeReport = timeData;
            this.heading = 'Time Data Report for ' + this.timeReports.employeeName
            if (this.selectedTimeReport.startDate != null) {
                this.heading += ' from ' + this.selectedTimeReport.startDate;
            }
            if (this.selectedTimeReport.endDate != null) {
                this.heading += ' to ' + this.selectedTimeReport.endDate;
            }
        },

        showList() {
            this.list = true;
            this.selectedTimeReport = {};
        },
    }
}
</script>

<style scoped>

</style>