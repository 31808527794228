<template>
    <section class="section p-0 full-width" style="padding-top: 0px">
      <ui5-table id="time-report-table" sticky-column-header >
        <ui5-table-column slot="columns" class="date">
            <ui5-label wrapping-type="Normal">Date</ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="planned-hours">
                <ui5-label wrapping-type="Normal">Planned Hours</ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="absence-hours">
                <ui5-label wrapping-type="Normal">Absence Hours</ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="actual-hours">
                <ui5-label wrapping-type="Normal">Actual Hours</ui5-label>
        </ui5-table-column>
        <ui5-table-column slot="columns" class="absence-type">
                <ui5-label wrapping-type="Normal">Absence Type</ui5-label>
        </ui5-table-column>

      <ui5-table-row v-for="report in timeReport.detailedMonthReportList"
      :key="timeReport.detailedMonthReportList.date">
        <ui5-table-cell>
            {{ report.date }}
        </ui5-table-cell>
        <ui5-table-cell class="hour-cell">
            {{ report.plannedHours }}
        </ui5-table-cell>
        <ui5-table-cell class="hour-cell">
            {{ report.absenceHours }}
        </ui5-table-cell>
        <ui5-table-cell class="hour-cell">
            {{ report.actualHours }}
        </ui5-table-cell>
        <ui5-table-cell>
            {{ report.absenceTimeTypeName }}
        </ui5-table-cell>
      </ui5-table-row>
      </ui5-table>
  </section>
</template>

<script>

export default {
    name: 'TimeReportDetailed',

    props: {
        timeReport: {
            type: Object,
            default: () => {},
        }
    },
    data: function () {
        return {
            busyLoading: false,
        }
    }
}
</script>

<style scoped>
#time-report-table {
  width: 100%;
  border-collapse: collapse;
}

ui5-table-column {
  text-align: left;
}

.hour-cell {
  text-align: right;
  padding: 8px;
  background-color: #f9f9f9;
  font-weight: bold;
}

ui5-table-cell:first-child {
  font-weight: bold;
  background-color: #e0e0e0;
  padding: 10px;
}

ui5-table-cell {
    text-align: center;
}

</style>