<template>
  <div></div>
</template>

<script>
import Vue from 'vue';

const BlankSpace = Vue.component('BlankSpace', {
  name: 'BlankSpace',
  methods: {
    isValid() {
      return true;
    },
  },
});

export default BlankSpace;
</script>
