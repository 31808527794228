import { render, staticRenderFns } from "./CaseModal.vue?vue&type=template&id=38755d0e&scoped=true&"
import script from "./CaseModal.vue?vue&type=script&lang=js&"
export * from "./CaseModal.vue?vue&type=script&lang=js&"
import style0 from "./CaseModal.vue?vue&type=style&index=0&id=38755d0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38755d0e",
  null
  
)

export default component.exports